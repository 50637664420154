<template>
    <div class="col-right">
        <a-spin :spinning="spinning">
            <a-card
                    style="width:100%"
            >
                <a-tabs size="small" :tabBarGutter="0" v-model="tabsActiveIdx" @change="changeTab">
                    <a-tab-pane v-for="(tab, idx) in tabList" :key="idx" :tab="tab.tab">
                    </a-tab-pane>
                </a-tabs>

                <div
                        class="ant-table-wrapper ng-star-inserted">
                    <div
                            class="ant-spin-nested-loading">
                        <div class="ant-spin-container ng-star-inserted">
                            <div
                                    class="ant-table ant-table-bordered">
                                <div
                                        class="ant-table-container ng-star-inserted">
                                    <div
                                            class="ant-table-content">
                                        <table div-content="" style="table-layout: auto;">
                                            <col class="ng-star-inserted">
                                            <col class="ng-star-inserted" style="width: 120px; min-width: 120px;">
                                            <col class="ng-star-inserted" style="width: 110px; min-width: 110px;">
                                            <col class="ng-star-inserted" style="width: 120px; min-width: 120px;">
                                            <col class="ng-star-inserted" style="width: 90px; min-width: 90px;">
                                            <col class="ng-star-inserted" style="width: 100px; min-width: 100px;">
                                            <thead class="ant-table-thead ng-star-inserted">
                                            <tr class="ant-table-row ng-star-inserted">
                                                <th class="ant-table-cell" style="text-align: center;">商品</th>
                                                <th nzwidth="120px" class="ant-table-cell" style="text-align: center;">所属订单
                                                </th>
                                                <th nzwidth="110px" class="ant-table-cell" style="text-align: center;">售后金额</th>
                                                <th  nzwidth="120px" class="ant-table-cell" style="text-align: center;">售后类型</th>
                                                <th nzwidth="90px" class="ant-table-cell" style="text-align: center;">售后状态</th>
                                                <th nzwidth="100px" class="ant-table-cell" style="text-align: center;">操作</th>
                                            </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody ng-star-inserted">
                                            <template v-for="(item, idx) in refundList">
                                                <tr class="ant-table-expanded-row ng-star-inserted" :key="idx">
                                                    <td class="nz-disable-td ant-table-cell" colspan="6">
                                                        <div class="order-line ng-star-inserted">
                                                            <div class="order-line-number">
                                                                <label style="margin-right: 2px;" class="ant-checkbox-wrapper ng-untouched ng-pristine ng-valid">
                                                                <span class="ant-checkbox">
                                                                    <input type="checkbox" class="ant-checkbox-input ng-untouched ng-pristine ng-valid">
                                                                    <span class="ant-checkbox-inner"></span>
                                                                </span>
                                                                    <span></span>
                                                                </label>
                                                                售后单号：{{item.refund_no}}
                                                                <div class="ng-tns-c228-16 ng-trigger ng-trigger-fadeMotion ant-tag-red ant-tag ng-star-inserted">
                                                                    自营
                                                                </div>&nbsp;&nbsp;&nbsp;&nbsp; 申请时间：{{item.refund_at}}&nbsp;&nbsp;&nbsp;&nbsp;
                                                                下单渠道: ????
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="ant-table-row ng-star-inserted" v-for="(goods, gIdx) in item.orderList" :key="gIdx">
                                                    <td class="ant-table-cell">
                                                        <div class="order-goods" style="justify-content: space-between;">
                                                            <div style="display: flex; flex: 1;">
                                                                <div class="order-goods-img">
                                                                    <img :src="goods.goods_logo">
                                                                </div>
                                                                <div class="order-goods-info">
                                                                    <p style="margin: 0;">{{goods.goods_title}}</p>
                                                                    <p class="center-space">
                                                                        <span class="ng-star-inserted">{{goods.goods_spec}} </span>
                                                                    </p>
                                                                    <p style="margin: 0;">数量：{{goods.number_goods}}</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td rowspan="1" class="ant-table-cell ng-star-inserted" style="text-align: center;">
                                                        {{item.order_no}}</td>
                                                    <td rowspan="1" class="ant-table-cell ng-star-inserted" style="text-align: center;">
                                                        <p style="margin: 0;">¥{{item.price_goods}}</p>
                                                        <p style="margin: 0;">+</p>
                                                        <p style="margin: 0;">运费：{{item.price_express}}</p>
                                                    </td>
                                                    <td rowspan="1" class="ant-table-cell ng-star-inserted" style="text-align: center;">
                                                        {{item.refund_state_desc}}</td>
                                                    <td rowspan="1" class="ant-table-cell ng-star-inserted" style="text-align: center;">
                                                        {{item.refund_state_desc}} </td>
                                                    <td rowspan="1" class="ant-table-cell ng-star-inserted" style="text-align: center;">
                                                        <div class="order-line-price">
                                                            <a class="ant-btn ant-btn-link" @click="$jumpTo('/buyer/refund-detail/'+item.id)">
                                                                <span class="ng-star-inserted">查看详情</span>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="pagination" v-if="total > 0">
                                <a-pagination
                                        v-model="queryParams.page"
                                        :total="total"
                                        :page-size="queryParams.pageSize"
                                >
                                </a-pagination>
                            </div>
                        </div>
                    </div>
                </div>

            </a-card>
        </a-spin>
    </div>
</template>

<script>
    import service from "../../../utils/request";

    export default {
        name: "refund-list",
        data() {
            return {
                spinning: false,
                total: 0,
                tabList: [
                    {
                        key: '0',
                        tab: '全部',
                    },
                    {
                        key: '1',
                        tab: '售后中',
                    },
                    {
                        key: '2',
                        tab: '售后完成',
                    },

                ],
                tabsActiveIdx: 0,
                refundList: [],
                queryParams:{
                    refundStatus: 0,
                    page: 1,
                    pageSize: 10
                }
            }
        },
        created(){
            this.getList();
        },
        methods: {
            getList(){
                this.spinning = true;
                service.post(service.uri.order.refundList, this.queryParams).then(res => {
                    this.spinning = false;
                    if (res.code == 200) {
                        this.refundList = res.data.list;
                        this.total = res.data.total;
                    }
                });
            },
            changeTab(e) {
                console.log(e);
                this.queryParams.refundStatus = e;
                this.getList();
            },
        }
    }
</script>

<style lang="scss" scoped>

    .ant-table.ant-table-bordered .ant-table-container {
        border: 1px solid #f0f0f0;
        border-right: 0;
        border-bottom: 0;
    }


    .ant-table-thead > tr > th {
        background: #eee;
        padding: 9px 16px;
        font-size: 12px;
    }

    .ant-table-expanded-row > .ant-table-cell {
        background: #f7f7f7;
        font-size: 11px;
        padding: 10px 20px;
        border-bottom: 9px solid #fff;
        border-top: 20px solid #fff;
    }

    .order-goods {
        display: flex;
        text-align: left;

        .order-goods-img {
            width: 80px;
            height: 80px;
            margin-right: 10px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }

        .order-goods-info {
            display: flex;
            flex-direction: column;

            .center-space {
                margin: 0;
                flex: 1;
                width: 100%;
            }
        }
    }
</style>